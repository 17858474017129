import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showConfirm, hideConfirm } from './action';
import { ContentStyled } from './styled';

const PopupConfirm = ({ show, title, message, cancelTitle, confirmTitle, hideConfirm, onCancel, onConfirm }) => {
  const handleConfirm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onConfirm === 'function') {
      onConfirm(e);
    }

    hideConfirm();
  };

  const handleCancel = (e) => {
    if (typeof onCancel === 'function') {
      onCancel(e);
    }

    hideConfirm();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={hideConfirm}
    >
      {title && (
        <Modal.Header closeButton onHide={onCancel} >
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>)
      }
      <Modal.Body>
        <ContentStyled className="content">{message}</ContentStyled>
        <div className="actions">
          <Button id="btn_not_confirm" variant="secondary" onClick={handleCancel}>
            {cancelTitle}
          </Button>
          <Button id="btn_confirm" variant="primary" onClick={handleConfirm}>
            {confirmTitle}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapState = state => ({
  ...state.confirmDialog
});
const mapDispatch = { showConfirm, hideConfirm };
export default connect(mapState, mapDispatch)(PopupConfirm);
